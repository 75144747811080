.photos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  height: 480px;
  width: 100%;
  padding-inline: 15%;
  padding-bottom: 50px;
  border-radius: 10px;
}

.photos p {
  width: 600px;
  text-align: center;
  border-radius: 10px;
}

.photos-components {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50%;
  width: 100%;
  overflow: visible;
  border-radius: 10px;
}

.next,
.pre {
  width: 40px;
  height: 40px;
  overflow: visible;
  border-radius: 10px;
}

.next img,
.pre img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.photos h2 {
  border-bottom: 3px solid #4f9468;
  padding: 5px;
  padding-bottom: 10px;
}

.photos-component {
  width: 250px;
  aspect-ratio: 1.2;

  border-radius: 10px;
  overflow: visible;
  object-fit: cover;
}

.photos-component img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.disable {
  display: none;
}

@media only screen and (max-width: 650px) {
  .photos p {
    width: 450px;
  }
}

@media only screen and (max-width: 550px) {
  .photos p {
    width: 400px;
  }

  .photos {
    height: 450px;
  }
  .photos .photos-components {
    height: 52%;
  }
}

@media only screen and (max-width: 450px) {
  .photos p {
    width: 360px;
  }

  .photos {
    padding-inline: 5px;
  }

  .next,
  .pre {
    height: 35px;
    width: 35px;
  }
}

@media only screen and (max-width: 400px) {
  .photos-component {
    width: 200px;
  }

  .photos > p {
    font-size: 14px;
    width: 300px;
  }
}

@media only screen and (max-width: 350px) {
  .photos-component {
    width: 190px;
  }

  .photos > p {
    font-size: 12px;
    width: 250px;
  }

  .next,
  .pre {
    width: 30px;
    height: 30px;
  }
}
