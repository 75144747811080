.video {
    background-color: #f1f1f1;
    height: 650px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    text-align: right;
}

.video .div_video {
    height: 80%;
    background-color: black;
    overflow: hidden;
}
video {
    height: 100%;
}

.video p {
    height: fit-content;
    width: 530px;
    text-align: center;
}

@media only screen and (max-width: 1000px) {
    .video {
        height: 550px;
    }
    .video .div_video {
        height: 75%;
    }
}

@media only screen and (max-width: 800px) {
    .video {
        height: 500px;
    }
    .video .div_video {
        height: 70%;
    }
}

@media only screen and (max-width: 700px) {
    .video {
        height: 450px;
    }

    .video .div_video {
        height: 68%;
    }
}

@media only screen and (max-width: 600px) {
    .video h2 {
        font-size: 20px;
    }

    .video p {
        font-size: 14px;
        width: 400px;
    }

    .video {
        height: 400px;
    }

    .video .div_video {
        height: 70%;
    }
}

@media only screen and (max-width: 550px) {
    .video {
        height: 350px;
    }

    .video .div_video {
        height: 70%;
    }
}

@media only screen and (max-width: 450px) {
    .video {
        height: 300px;
    }
    .video p {
        font-size: 12px;
        width: 330px;
    }
}

@media only screen and (max-width: 370px) {
    .video p {
        font-size: 10px;
        width: 250px;
    }

    .video .div_video {
        height: 63%;
    }

    .video {
        height: 280px;
    }
}

@media only screen and (max-width: 300px) {
    .video {
        height: 250px;
    }
}
