.mid-components {
    display: flex;
    justify-content: space-evenly;

    height: 300px;
    width: 100%;
    padding-inline: 7.5%;
    padding-top: 20px;
    margin-bottom: 70px;
}

@media only screen and (max-width: 700px) {
    .mid-components {
        height: 650px;
        flex-direction: column;
        align-items: center;
    }
}
