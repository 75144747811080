/* .headerPhoto {
  width: 100%;
  height: 420px;
  position: relative;
  background-color: black;
  overflow: visible;
  margin-top: 50px;
} */

.headerPhoto {
  width: 100%;
  height: 350px;
  position: relative;

  overflow: visible;
  margin-top: 100px;
}

.headerPhoto img {
  width: 100%;
  height: 100%;
  /* filter: brightness(55%); */
}

.headerPhoto span {
  position: absolute;
  display: inline-block;
  color: #3f3f3f;
  padding: 5px;
  border-bottom: 2px solid #3f3f3f;
  z-index: 0;
  top: 30%;
  right: 10%;
  font-size: 26px;
}

.button {
  background-color: #4f9468;
  color: #fff;

  font-size: 18px;
  width: 170px;
  height: 50px;
  margin-inline: 40px;
  text-align: center;

  border: none;
  border-radius: 5px;
}

.images img {
  width: 40px;
}

.container1 {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  padding-inline: 7.5%;
}

.errorImage {
  width: 500px;
}

.errorimgcontainer {
  margin-left: 25%;
}
@media only screen and (max-width: 850px) {
  .headerPhoto img {
    margin-top: 0px;
    padding: 0px;
  }

  .headerPhoto {
    margin-top: 65px;
    padding: 0px;
    height: 120px;
    position: relative;

    overflow: visible;
  }

  .headerPhoto span {
    position: absolute;
    display: inline-block;

    font-size: 12px;
    top: 30%;
    z-index: 0;
    padding: 0px;
  }
  .container1 {
    flex-direction: column;
  }

  .errorImage {
    width: 100px;
  }

  .button {
    font-size: 12px;
    width: 100px;
    height: 50px;

    text-align: center;

    border: none;
    border-radius: 5px;
  }

  .errorimgcontainer {
    margin-left: 0;
  }
}
