@font-face {
  font-family: Yekan;
  src: url("assets/fonts/Newfolder/Yekan.ttf") format("truetype");
  font-weight: 400px;
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: Yekan-Bold;
  src: url("assets/fonts/Newfolder/Yekan.eot") format("truetype");
}
@font-face {
  font-family: Yekan-Bold;
  src: url("assets/fonts/Newfolder/Yekan.woff") format("truetype");
}
