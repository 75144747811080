.accordian {
  width: 100%;
  min-height: 60px;
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 5px;
  margin-inline: auto;
}

.accordian-header {
  display: flex;
  padding-inline: 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  cursor: pointer;
  background-color: #f1f1f1;
  border-radius: 5px;
}

.accordian-header span {
  font-size: 28px;
  font-weight: bold;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 30px;
  color: #555;
}

.accordian-main {
  display: none;
}

.accordian-main.show {
  display: flex;
  width: 100%;
  min-height: 50px;
  height: fit-content;
  padding: 10px;
  text-align: right;
}

.accordian-main p {
  width: 100%;
  word-wrap: break-word;
}

@media only screen and (max-width: 1000px) {
  .accordian-header p {
    font-size: 14px;
  }

  .accordian-header span {
    font-size: 20px;
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 850px) {
  .accordian-header span {
    width: 20px;
  }
  .accordian-header p {
    font-size: 12px;
  }
  .accordian-header {
    padding-inline: 10px;
  }
}
