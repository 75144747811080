.mid-component {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 20%;
  height: 100%;
  text-align: center;
}

.mid-component div {
  width: 100px;
  height: 100px;

  transform: rotateZ(45deg);
  background-color: transparent;
  border: 2px solid #4f9468;

  border-radius: 10px;
  overflow: hidden;
  object-fit: cover;

  padding: 16px;
}

.mid-component div img {
  margin-inline: auto;
  transform: rotateZ(-45deg);
}

.mid-component p {
  text-align: center;
}

@media only screen and (max-width: 900px) {
  .mid-component div {
    width: 90px;
    height: 90px;
    padding: 11px;
  }

  .mid-component h3 {
    font-size: 16px;
  }

  .mid-component p {
    font-size: 13px;
  }
  .mid-component div img {
    margin-inline: auto;
    transform: rotateZ(-45deg);
  }
}

@media only screen and (max-width: 700px) {
  .mid-component {
    height: 33%;
    width: 80%;
  }
}
