@import url(fonts.css);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  text-align: right;
  font-family: Yekan;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Yekan,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Yekan, "Courier New",
    monospace;
}

#root {
  width: 100vw;
  height: 100vh;
  font-family: Yekan;
}

h2 {
  font-size: 28px;
  color: #525151;
  font-family: Yekan;
}

h3 {
  color: #454545;
}

p {
  color: #555;
  font-family: Yekan;
}
.btn :hover {
  background-color: #f1f1f1;
  color: #4f9468;
  border: 1px solid #4f9468;
  cursor: pointer;
}

.btn2 :hover {
  background-color: #4f9468;
  color: #f1f1f1;
  border: 1px solid #f1f1f1;
  cursor: pointer;
}

button {
  font-family: Yekan;
}

.change {
  color: #4f9468;
  border: none;
  color: #dbdbdb;
  font-family: Yekan;
}

.change :hover {
  color: #4f9468;
}
