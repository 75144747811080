.accordians-component {
  min-height: 480px;
  height: fit-content;
  width: 100%;
  text-align: center;
  margin-inline: auto;
  padding: 10px;
  margin-top: 15px;
  padding-inline: 7.5%;
}

.accordians-component h2,
.accordians-component > p {
  height: 50px;
  margin-inline: auto;
  text-align: center;
}

.accordians-component-main {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  margin-top: 10px;
  margin-inline: auto;
  text-align: center;
}

.accordians-sidebar > a {
  padding: 0;
  width: fit-content;
  margin-inline: auto;
  text-align: center;
}

.accordians-component-sidebar {
  width: 200px;
  height: fit-content;
  margin-inline: auto;
  text-align: center;
}

.accordians-sidebar:not(:last-of-type) {
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 2px solid #c5c5c5;
}

.accordians-sidebar {
  width: 100%;
  height: 150px;
  text-align: center;
  padding-bottom: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.accordians-sidebar h3 {
  width: 100%;
}

.accordians-sidebar div {
  background-color: #4f9468;
  width: fit-content;
  min-width: 120px;
  padding: 15px;
  padding-inline: 5px;
  border-radius: 20px;
  margin-top: 10px;
  color: #dbdbdb;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline: auto;
  text-align: center;
}

.accordians {
  width: calc(100% - 250px);
  min-height: 250px;
  /* margin-left: 50px; */
  margin-inline: auto;

  text-align: center;
}

.accordians-component > p {
  height: fit-content;
}
@media only screen and (max-width: 1000px) {
  .accordians-component {
    padding-inline: 5%;
  }

  .accordians-component-sidebar {
    width: 175px;
  }

  .accordians {
    width: calc(100% - 200px);
    margin-left: 30px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 850px) {
  .accordians-component-main {
    flex-direction: column-reverse;
    align-items: center;
  }

  .accordians {
    width: 95%;
    margin-left: 0;
  }

  .accordians-component-sidebar {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    width: 80%;
    height: fit-content;
  }

  .accordians-component-sidebar p {
    height: fit-content;
  }

  .accordians-sidebar {
    width: 45%;
    height: fit-content;
    padding: 0;
  }

  .accordians-sidebar:not(:last-of-type) {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
    border-left: #c5c5c5 2px solid;
    padding-left: 10px;
    box-sizing: content-box;
  }
}
@media only screen and (max-width: 500px) {
  .accordians-component-sidebar .accordians-sidebar p {
    font-size: 12px;
  }

  .accordians-component-main > * {
    width: 95%;
  }

  .accordians-component > p {
    font-size: 13px;
  }

  .accordians-sidebar div {
    padding: 5px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 7px;
    font-size: 13px;
    min-width: 100px;
  }
}
