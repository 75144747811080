/* .header {
  width: 100%;
  height: 419px;
  box-sizing: border-box;
  background-image: url("../../../assets/images/photo_2023-09-07_20-01-07\ 1.svg");
}

.headertext {
  width: 100%;
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: underline;
  padding-top: 180px;
  padding-right: 7.5%;
} */

.headerPhoto {
  width: 100%;
  height: 350px;
  position: relative;

  overflow: visible;
  margin-top: 100px;
}

.headerPhoto img {
  width: 100%;
  height: 100%;
}

.headerPhoto span {
  position: absolute;
  display: inline-block;
  color: #3f3f3f;
  padding: 5px;
  border-bottom: 2px solid #3f3f3f;
  z-index: 0;
  top: 30%;
  right: 10%;
  font-size: 26px;
}

.p {
  color: #555;
  text-align: right;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 80%;
  margin-inline: auto;

  margin-top: 80px;
}

.imgSection {
  display: flex;
  width: 90%;
  justify-content: space-around;
  padding-top: 100px;
  padding-left: 20%;

  text-align: center;
  margin: auto;
}

.pimg {
  height: 400px;
  margin: auto;
}

.h4 {
  color: #dbdbdb;
  text-align: right;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-right: 25%;
  width: 700px;
}

.h2 {
  color: #dbdbdb;
  text-align: right;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  padding-right: 25%;
}

.cccc {
  padding-left: 400px;
}

.cbutton {
  border-radius: 10px;
  background: #dbdbdb;
  color: #4f9468;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 120px;
  height: 40px;
  margin-top: 40px;
  text-align: center;
  border: none;
}

.container {
  height: 150px;
  width: 100%;
  background-color: #4f9468;
  margin-top: 200px;
}

.container2 {
  display: flex;
  direction: rtl;
  width: 100%;
  justify-content: space-around;
  margin-top: 40px;
}

.container3 {
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-top: 150px;
}

.container4 {
  display: flex;
  direction: rtl;
  text-align: center;
  margin: auto;
  padding-left: 35%;
}

.eText {
  font-size: 23px;
  color: #555;
  padding-top: 20px;
}

.title {
  padding-right: 10%;
}

.cccc h4 {
  font-size: 16px;
}

.hhh {
  padding-right: 10%;
}

@media only screen and (max-width: 850px) {
  .cccc {
    padding: 0px;
  }

  .cccc h4 {
    font-size: 14px;
  }

  .cccc h2 {
    font-size: 16px;
  }
  .headerPhoto img {
    margin-top: 0px;
    padding: 0px;
  }

  .headerPhoto {
    margin-top: 70px;
    padding: 0px;
    height: 120px;
    position: relative;

    overflow: visible;
  }

  .headerPhoto span {
    position: absolute;
    display: inline-block;

    font-size: 16px;
    top: 27%;
    z-index: 0;
    padding: 0px;
  }
  .p {
    font-size: 12px;
  }

  .imgSection {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-right: 70px;

    width: 100%;
  }

  .jh {
    text-align: center;
  }

  .pimg {
    margin-inline: auto;
    margin-top: 10px;
    height: 400px;
  }
  .container {
    height: 150px;
  }

  .container2 {
    flex-direction: column;
    text-align: center;
    padding-right: 8%;
  }

  .container3 {
    margin-top: 50px;
    text-align: center;

    flex-direction: column;
  }

  .title {
    padding: 0;
    text-align: center;
    margin-inline: auto;
    margin-bottom: 20px;
  }

  .eText {
    font-size: 18px;
    color: #555;
    padding-top: 17px;
    padding-right: 5px;
    width: 100%;
    margin-inline: auto;
  }

  .hhh {
    padding-right: 0;
    padding-inline: 5px;
  }

  .container4 {
    text-align: center;
    display: flex;
    direction: rtl;
    text-align: center;
    margin: auto;
    padding-left: 0;
  }

  .cbutton {
    border-radius: 10px;
    background: #dbdbdb;
    color: #4f9468;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100px;
    height: 30px;

    text-align: center;
    border: none;
  }
}
