.text {
    display: none;
    width: 80%;
    min-height: 60%;

    background-color: #fff;
    position: relative;
    text-align: right;
    overflow: visible;
    padding: 10px;
    padding-bottom: 13px;
    padding-inline: 5%;
    margin-bottom: 50px;
    font-size: 14px;
    line-height: 25px;
}

.show {
    transform-origin: left;
    transition: transform 1s linear;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text::after {
    content: "";
    width: 15px;
    height: 15px;

    position: absolute;
    bottom: -8px;
    right: 75px;
    background-color: #fff;

    transform: rotateZ(45deg);
}

.text .slider-info {
    display: block;
    position: absolute;
    bottom: -110px;
    right: 75px;
    transform: translateX(50%);
    width: 250px;
    height: 100px;
    text-align: center;
    font-size: 13px;
}

@media only screen and (max-width: 1100px) {
    .text {
        width: 90%;
    }
}

@media only screen and (max-width: 950px) {
    .text {
        font-size: 14px;
    }
}

@media only screen and (max-width: 800px) {
    .text {
        width: 95%;
    }
}

@media only screen and (max-width: 800px) {
    .text {
        font-size: 12px;
    }
}
