.headerPhoto {
  width: 100%;
  height: 350px;
  position: relative;

  overflow: visible;
  margin-top: 100px;
}
.headerPhoto img {
  width: 100%;
  height: 100%;
  /* filter: brightness(55%); */
}

.headerPhoto span {
  position: absolute;
  display: inline-block;
  color: #3f3f3f;
  padding: 5px;
  border-bottom: 2px solid #3f3f3f;
  z-index: 0;
  top: 30%;
  right: 10%;
  font-size: 26px;
}

.pp {
  color: #3f3f3f;
  text-align: center;

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 80%;
  margin-inline: auto;
  margin-top: 100px;
}

.productSecrion {
  direction: rtl;
  display: flex;

  margin-top: 50px;
  width: 100%;
}

.pimg {
  width: 100%;
  height: 400px;
  margin-top: 130px;
}

.producttext {
  padding-top: 160px;
  padding-right: 7.5%;
}

.ptext1 {
  color: #3f3f3f;
  text-align: justify;

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 600px;
}

.ptext2 {
  color: #3f3f3f;
  text-align: justify;

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 600px;
  padding-top: 0px;
}

.cbutton {
  border-radius: 10px;
  background: #4f9468;
  width: 120px;
  height: 40px;
  color: #fff;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  border: none;
}

a {
  all: unset;
  width: 40%;
  padding-top: 150px;
}

.endt {
  color: #3f3f3f;
  font-size: 16px;
  margin: 0px;
  padding: 0px;
  padding-top: 20px;
}

.endt2 {
  color: #3f3f3f;
  font-size: 16px;
  margin: 0px;
  padding: 0px;
  padding-top: 35px;
}

.timgs {
  display: flex;
  flex-direction: column;
  height: 120px;
  width: 50px;
  padding-top: 10px;
  padding-left: 5px;
}

@media only screen and (max-width: 850px) {
  .headerPhoto img {
    margin-top: 0px;
    padding: 0px;
  }

  .headerPhoto {
    margin-top: 70px;
    padding: 0px;
    height: 120px;
    position: relative;

    overflow: visible;
  }

  .headerPhoto span {
    position: absolute;
    display: inline-block;

    font-size: 16px;
    top: 27%;
    z-index: 0;
    padding: 0px;
  }
  .pp {
    color: #3f3f3f;
    text-align: right;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 80%;
    margin-inline: auto;
    margin-top: 100px;
  }
  .pimg {
    width: 300px;
    justify-content: center;
    margin-top: 0px;
  }

  .productSecrion {
    direction: rtl;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .ptext1 {
    color: #3f3f3f;
    text-align: justify;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 10px;
  }

  .ptext2 {
    color: #3f3f3f;
    text-align: justify;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 2px;
    width: 80%;
  }

  .producttext {
    padding-top: 50px;
    padding-left: 10px;
  }

  .endt {
    color: #3f3f3f;
    font-size: 10px;
    margin: 0px;
    padding: 0px;
    padding-top: 20px;
    width: 100%;
  }

  .endt2 {
    color: #3f3f3f;
    font-size: 10px;
    margin: 0px;
    padding: 0px;
    padding-top: 45px;
  }

  .timgs {
    height: 120px;
    width: 40px;
    padding-top: 10px;
    padding-left: 5px;
  }

  .timg2 {
    padding-top: 10px;
  }
}
