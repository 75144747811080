.contact-us-page {
  direction: rtl;
  width: 100%;
}

/* .header-photo {
    width: 100%;
    height: 420px;
    position: relative;
    background-color: black;
    overflow: visible;
} */

/* .headerPhoto {
  width: 100%;
  height: 420px;
  position: relative;
  background-color: black;
  overflow: visible;
  margin-top: 50px;
} */

.header-photo {
  width: 100%;
  height: 350px;
  position: relative;

  overflow: visible;
  margin-top: 100px;
}
.header-photo img {
  width: 100%;
  height: 100%;
  /* filter: brightness(55%); */
}

.header-photo span {
  position: absolute;
  display: inline-block;
  color: #3f3f3f;
  padding: 5px;
  border-bottom: 2px solid #3f3f3f;
  z-index: 3;
  top: 30%;
  right: 10%;
  font-size: 26px;
  z-index: 0;
}

.contact-us-main {
  min-height: 330px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: right;
  padding: 10px;
  padding-bottom: -20px;
  margin-right: 7.8%;
  margin-top: 50px;
  padding-top: 30px;
}

.contact-us-main-text {
  min-height: 100px;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  margin-bottom: 50px;
}

.contact-us-main-text h2 {
  font-size: 32px;
  font-weight: bold;
}

.contact-us-main-text p {
  font-size: 17px;
}

.contact-us-items {
  width: 100%;
  min-height: 100px;
}

.contact-us-items h3 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}

.contact-us-items-container {
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;
  gap: 12px;
  column-gap: 100px;
  font-size: 14px;
  font-weight: bold;
}

.contact-us-item {
  color: #4f9468;
  font-size: 20px;
  font-weight: bold;
}

@media only screen and (max-width: 900px) {
  .contact-us-main {
    margin-inline: 5%;
  }
}

@media only screen and (max-width: 750px) {
  .contact-us-main {
    padding-top: 20px;
  }

  .contact-us-items-container {
    column-gap: 70px;
  }

  .contact-us-main-text h2 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 600px) {
  .contact-us-main {
    margin-top: 30px;
    padding-top: 10px;
  }
  .contact-us-main-text {
    margin-bottom: 30px;
  }
  .contact-us-main-text p {
    font-size: 15px;
  }

  .contact-us-items-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 500px) {
  .contact-us-main-text h2 {
    font-size: 24px;
  }

  .header-photo img {
    margin-top: 0px;
    padding: 0px;
  }

  .header-photo {
    margin-top: 70px;
    padding: 0px;
    height: 120px;
    position: relative;

    overflow: visible;
  }

  .header-photo span {
    position: absolute;
    display: inline-block;

    font-size: 16px;
    top: 27%;
    z-index: 0;
    padding: 0px;
  }
}
