.navbar {
  width: 100%;
  height: 100px;
  position: fixed;
  direction: rtl;
  overflow: visible;
  z-index: 1;
  background-color: #fff;
}

.navItems {
  display: flex;
  justify-content: center;
  width: 550px;
  height: 100%;
  align-items: center;
  margin-right: 21%;
  overflow: visible;
  /* background: blue; */
}

.navItem {
  margin-inline: 5px;
  cursor: pointer;
  position: relative;
  overflow: visible;
  min-width: 50px;
  text-align: center;
  padding: 10px;
  padding-block: 20px;
  border-radius: 10px;
}

.k {
  margin-inline: 5px;
  cursor: pointer;
  position: relative;
  overflow: visible;
  min-width: 50px;
  text-align: center;
  padding: 10px;
  padding-block: 20px;
  border-radius: 10px;
}

.k :hover {
  text-decoration: none;
}

.navItem :hover {
  text-decoration: underline;
  text-decoration-color: #4f9468;
  text-decoration-thickness: 3px;
  text-underline-offset: 10px;
}

.navbar > div:first-of-type {
  position: absolute;
  right: 7%;
  /* background-color: rgba(253, 4, 4, 0.33); */
}

.navbar > div:last-of-type {
  position: absolute;
  left: 7%;
}

.drop_down {
  position: absolute;
  display: none;
  width: fit-content;
  padding-right: 10px;
  color: #000;

  right: 0;
  top: 100%;
  width: 200px;

  background-color: white;
  border-radius: 10px;
}

.drop_down a {
  width: 100%;
  padding-top: 0;
  margin-block: 10px;
}

.navItem:hover a + .drop_down,
.drop_down:hover {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
  text-decoration: none;
}

.soon {
  font-size: 12px;
  overflow: visible;
  position: absolute;
  display: none;
  text-align: center;
  padding-right: 15px;
  color: #4f9468;
}

.k:hover a + .soon,
.soon:hover {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
}
.responsiveNavbar {
  height: 100px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
  /* background-color: aqua; */
}

.rightNavbar {
  width: 220px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
}

.leftNavbar {
  width: 180px;
  height: 100%;
  /* background-color: red; */
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.leftNavbarPhone {
  display: flex;
  align-items: center;
  width: 70%;
  display: flex;
  justify-content: space-between;
}

.leftNavbarPhone p {
  font-size: 13px;
  font-weight: bold;
}

.leftNavbarPhone p:last-of-type {
  font-size: 11px;
}

.phoneIcon {
  width: 25px;
}

.instagramIcon {
  width: 25%;
  overflow: visible;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
}

.instagramIcon a {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

.instagramIcon a img {
  height: 100%;
  width: 100%;
  margin-top: 5px;
}

.drop_down_menu {
  width: 100%;
  height: 200px;
  position: absolute;
  background-color: #ffffff;
  z-index: 2;
  bottom: -200px;
  display: none;
  overflow: visible;
}
.dropdownshow {
  display: flex;
}

.line {
  position: absolute;
  width: 2px;
  height: 70%;
  background-color: #4f9468;
  left: -4%;
  top: 50%;
  transform: translateY(-50%);
}

.lefTextcontainer {
  direction: ltr;
  text-align: left;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-content: flex-end;
}

@media only screen and (max-width: 1200px) {
  .navbar > div:first-of-type {
    right: 6%;
  }
  .navbar > div:last-of-type {
    left: 6%;
  }

  .navItems {
    margin-right: 19%;
  }
}

@media only screen and (max-width: 1100px) {
  .navbar > div:first-of-type {
    right: 5%;
  }
  .navbar > div:last-of-type {
    left: 5%;
  }

  .navItems {
    margin-right: 18.8%;
  }
}

@media only screen and (max-width: 1000px) {
  .navbar > div:first-of-type {
    right: 3.5%;
    left: 10%;
  }

  .navItems {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 0;
    align-items: flex-start;
  }

  .navItem {
    text-align: right;
    height: 20px;
    padding: 0;
    padding-left: 5px;
    min-width: 10px;
  }

  .navItem a {
    padding-top: 0;
  }

  .drop_down {
    top: 0;
    transform: translateY(-6%);
    right: 100%;
    width: 250px;
    height: 25px;

    /* color: black; */
  }

  .navItem:hover a + .drop_down,
  .drop_down:hover {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: visible;
  }

  .soon {
    top: 0;
    transform: translateY(-6%);
    right: 100%;
    width: 250px;
    height: 25px;
    color: #4f9468;
  }

  .navItem:hover a + .soon,
  .soon:hover {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: visible;
  }
}

@media only screen and (max-width: 700px) {
  .leftNavbar {
    width: 170px;
  }

  .rightNavbar {
    width: 200px;
  }
}

@media only screen and (max-width: 600px) {
  .leftNavbar {
    width: 120px;
  }

  .rightNavbar {
    width: 150px;
  }

  .phoneIcon {
    width: 20px;
  }

  .leftNavbarPhone p {
    font-size: 12px;
    text-align: right;
  }

  .leftNavbarPhone p:last-of-type {
    font-size: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .leftNavbar {
    width: 160px;
  }

  .rightNavbar {
    width: 180px;
  }

  .navbar > div:first-of-type {
    left: 7%;
  }
}
@media only screen and (max-width: 450px) {
  .leftNavbar {
    width: 140px;
  }

  .line {
    left: 0;
  }

  .rightNavbar {
    width: 150px;
  }

  .phoneIcon {
    width: 17px;
  }

  .navbar > div:first-of-type {
    left: 5%;
    right: 3px;
  }

  .leftNavbarPhone p {
    font-size: 10.5px;
  }
  .leftNavbarPhone p:last-child {
    font-size: 9.5px;
  }
}

@media only screen and (max-width: 300px) {
  .navbar {
    background-color: #4f9468;
  }
}
