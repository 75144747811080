.container {
  width: 100%;
  /* height: 689px; */
  height: fit-content;
  background-color: #525151;
  margin-top: 100px;
  direction: rtl;
  padding-inline: 7.5%;
  padding-top: 20px;
}

.footerItem {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  text-align: right;
  padding-top: 100px;
}

.f1 {
  text-decoration: underline;
  text-decoration-color: #4f9468;
  text-decoration-thickness: 3px;
  height: 50px;
  text-underline-offset: 10px;
}

.social_medias_links {
  display: flex;
  width: 300px;
  justify-content: left;
}

.container1 {
  display: flex;
  justify-content: space-between;
  width: 750px;
  padding-inline: 20px;
}

.ft {
  padding-right: 55px;
}

@media only screen and (max-width: 800px) {
  .container > div:first-of-type {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .footerItem {
  }

  .ft {
    margin: 0px;
    padding: 0;
  }
  .container1 {
    display: flex;
    flex-direction: column;

    padding-inline: 20px;
  }
}
