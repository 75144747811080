/* .headerPhoto {
  width: 100%;
  height: 420px;
  position: relative;
  background-color: black;
  overflow: visible;
  margin-top: 50px;
} */

.headerPhoto {
  width: 100%;
  height: 350px;
  position: relative;

  overflow: visible;
  margin-top: 100px;
}
.headerPhoto img {
  width: 100%;
  height: 100%;
  /* filter: brightness(55%); */
}

.headerPhoto span {
  position: absolute;
  display: inline-block;
  color: #3f3f3f;
  padding: 5px;
  border-bottom: 2px solid #3f3f3f;
  z-index: 3;
  top: 30%;
  right: 10%;
  font-size: 26px;
  z-index: 0;
}

.headertext {
  width: 100%;
  color: #3f3f3f;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: underline;
  padding-top: 180px;
  padding-right: 7.5%;
}

.cbutton {
  border-radius: 15px;
  background: #4f9468;
  color: #fff;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  width: 120px;
  height: 40px;
  margin-left: 7.5%;
  margin-top: 30px;
  border-radius: 10px;
  cursor: pointer;
  border: none;
}

.imagesSection {
  width: 300px;
  height: 350px;
  box-sizing: border-box;
  background-image: url("../../assets/images/about/Rectangle\ 335.svg");
  margin-top: 60px;
}

.imagesSection1 {
  width: 300px;
  height: 350px;
  box-sizing: border-box;
  background-image: url("../../assets/images/about/IMG_04672211\ 1.svg");
  margin-top: 60px;
}

.imagetxt {
  width: 220px;
  height: 50px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.39);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: underline;
  padding-right: 20px;
  margin-top: 250px;
  margin-left: 80px;
}

.sectext {
  width: 300px;
  justify-content: center;
  margin-top: 15px;
  font-size: 24px;
}

.container1 {
  width: 100%;
  background-color: #fff;
  padding-bottom: 80px;
}

.container2 {
  display: flex;
  direction: rtl;
  width: 100%;
  justify-content: space-around;
  margin-top: 40px;
}

.t1 {
  color: #555;
  width: 700px;
  font-size: 16px;
}

.container3 {
  background-color: #f1f1f1;
  width: 100%;
  
  display: flex;
  direction: rtl;
  justify-content: space-around;
  padding-left: 7.5%;
  padding-block: 30px;
}

.t2 {
  font-size: 18px;
  text-align: justify;
  width: 500px;
  padding-top: 100px;

}

.img1 {
  width: 40%;
  margin-right: 4.5%;
}

.container4 {
  display: flex;
  text-align: center;
  justify-content: space-around;
  padding-inline: 4%;
}
.container5 {
  display: flex;
  text-align: center;
  flex-direction: column;
}

.l1 {
  color: #555;
  font-size: 28px;
  text-align: center;
  margin-inline: auto;
}

.lt1 {
  color: #555;
  font-size: 18px;
  width: 350px;
  text-align: center;
  direction: rtl;
}

.endc {
  display: flex;
  justify-content: space-around;
  padding-inline: 4.5%;
  background: #f7f7f7;
  padding-bottom: 50px;
}

.h2tag {
  color: #3f3f3f;
  font-size: 35px;
  text-align: center;
}

.h2tag3 {
  color: #3f3f3f;
  font-size: 35px;
  padding-top: 20px;
}

.h2tag2 {
  color: #3f3f3f;
  font-size: 35px;
  text-align: center;
  margin-top: 100px;
  background: #f7f7f7;
  padding-top: 50px;
}
@media only screen and (max-width: 850px) {
  .headerPhoto img {
    margin-top: 0px;
    padding: 0px;
  }

  .headerPhoto {
    margin-top: 70px;
    padding: 0px;
    height: 120px;
    position: relative;

    overflow: visible;
  }

  .headerPhoto span {
    position: absolute;
    display: inline-block;

    font-size: 16px;
    top: 27%;
    z-index: 0;
    padding: 0px;
  }
  .container2 {
    display: flex;
    direction: rtl;
    width: 100%;
    flex-direction: column;
    padding-left: 5%;
    text-align: center;
    margin-inline: auto;
  }

  .container1 {
    width: 100%;
    background-color: #fff;
    padding: 5%;
  }

  .t1 {
    color: #555;
    width: 100%;
    font-size: 12px;
  }

  .container3 {
    flex-direction: column;

    padding-top: 30px;
    justify-content: center;
    text-align: center;
  }

  .img1 {
    width: 100%;
  }

  .t2 {
    padding: 5%;
    font-size: 12px;
    text-align: right;
    width: 100%;
    padding-top: 30px;
  }

  .container4 {
    flex-direction: column;
    margin-bottom: 100px;
  }

  .container5 {
    margin-block: 10px;
    text-align: center;
    justify-content: center;
    margin-inline: auto;
  }

  .l1 {
    color: #555;
    font-size: 20px;
    text-align: center;
    margin-inline: auto;
  }

  .lt1 {
    color: #555;
    font-size: 12px;
    width: 70%;
    text-align: center;
    margin-inline: auto;
  }

  .endc {
    display: flex;
    justify-content: space-around;

    background: #f7f7f7;
    padding-bottom: 50px;
    flex-direction: column;
  }

  .cc {
    text-align: center;
    padding-left: 18%;
  }
  .imagetxt {
    width: 100px;
    font-size: 12px;
    padding-top: 10px;
    margin-left: 210px;
  }

  .sectext {
    justify-content: center;
    margin-top: 15px;
    font-size: 12px;
  }

  .h2tag {
    font-size: 20px;
    margin-top: 0;
    padding-top: 0;
    background-color: #fff;
  }

  .h2tag2 {
    font-size: 20px;
    margin-top: 0;
    padding-top: 0;
  }

  .h2tag3 {
    color: #3f3f3f;
    font-size: 20px;
  }
}
